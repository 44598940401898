import styled from 'styled-components';
import { colors } from '../Themes/Theme';

const Form = styled.form `
    box-sizing: border-box;
    padding: 12px;
    width: 100%;
`
const InputText = styled.input`
    background: transparent;
    border-radius: 3px;
    border: solid 1px ${colors.linkColor};
    color: ${colors.fullWhiteColor};
    box-sizing: border-box;
    display:block;
    margin-bottom: 24px;
    padding: 12px;
    width: 100%;
`
const TextArea = styled.textarea`
    background: transparent;
    border-radius: 3px;
    border: solid 1px ${colors.linkColor};
    box-sizing: border-box;
    color: ${colors.fullWhiteColor};
    display:block;
    height: 200px;
    margin-bottom: 24px;
    padding: 12px;
    width: 100%;
`
const Label = styled.label`
    clear: both;
    color: ${colors.fullWhiteColor};
    color: ${colors.fullWhiteColor};
    display:block;
    margin-bottom: 12px;
    text-transform: uppercase;
    width: 100%;
`
const Button = styled.button`
    background: ${colors.linkColor};
    border: solid 1px ${colors.linkColor};
    border-radius: 3px;    
    color: ${colors.darkColor};
    font-weight: bold;
    text-transform: uppercase;
    padding: 12px;
    width: 100%;
`

export { InputText, TextArea, Label, Button, Form }