import Navigation from './Navigation';
import MenuToggle from './MenuToggle';
import React, { useEffect, useState, useRef } from "react";


const Menu = (props) => {
    const node = useRef();
    const [open, setOpen] = useState(false);

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    
    return (
        <div ref={node} >
            <MenuToggle className={open ? "close" : null} onClick={e => setOpen(!open)}>
                <span></span>
                <span></span>
                <span></span>
            </MenuToggle>
            {open && (
                <Navigation pages={props.pages} />
            )}
        </div>
    );
};

export default Menu;

