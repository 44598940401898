import React from 'react';
import MetaTags from 'react-meta-tags';

import FadeIn from 'react-fade-in';

const Home = (props) => {
    return (
        <>
            <MetaTags>
                <title>{props.pages.Title}</title>
                <meta name="description" content={props.pages.Description} />
            </MetaTags>
            <FadeIn transitionDuration="800" delay="250">
                <h1>{props.pages.Title}</h1>
                <p>{props.pages.Description}</p>
            </FadeIn>
        </>
    )
}

export default Home;