import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { colors } from '../Themes/Theme';
import FadeIn from 'react-fade-in';
const Navigation = (props) => {

    return (
        <nav>
            <NavMenu>
                <FadeIn wrapperTag="ul" childTag="li" delay="90">
                    {
                        props.pages.map((page) => {
                            return (
                                <>
                                    <Link to={page.Slug}>{page.MenuName}</Link>
                                </>
                            );
                        })
                    }
                </FadeIn>
            </NavMenu>
        </nav>

    );

}
export default Navigation;

const NavMenu = styled.div`
    ul{
        background: ${colors.mainColor};
        box-shadow: -10px 0px 10px 1px rgba(0,0,0,0.1);
        height: 100vh;
        margin: 0;
        list-style-type: none;
        padding: 24px;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% / 12 * 7);
        z-index: 2;
        li {
            margin-bottom: 24px;
            width: 80%;
        }
        a {        
            color: ${colors.linkColor};
            display: block;
            font-size: 2rem;
            text-decoration: none;
            text-transform: uppercase;
            margin-bottom: 12px;
            
        }
    }
`