//import React from "react";
const colors = {
    mainColor: "#00203FFF",
    linkColor: "#ADEFD1FF",
    headerColor: "#606060FF",
    darkColor: "#001326",
    fullWhiteColor: "#FFFFFF"
};

const size = {
    mobile: '425px',
    tablet: '768px',
    desktop: '1024px',
};

const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    desktop: `(min-width: ${size.desktop})`,
};
const fontsize = {
    small:'0.75rem',
    medium: '1rem',
    large: '1.25rem'
};

// NOTATION
// @media ${device.laptop} { 
//     stuff
//   }

export { colors, device, fontsize };