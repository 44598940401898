import React from 'react';
import MetaTags from 'react-meta-tags';
import FadeIn from 'react-fade-in';

const Prices = (props) => {

    return (
        <>
            <MetaTags>
                <title>{props.pages.Title}</title>
                <meta name="description" content={props.pages.Description} />
            </MetaTags>
            <FadeIn>
                <h1>{props.pages.Title}</h1>
                <p>Information about our prices</p>
            </FadeIn>
        </>
    )
}
export default Prices;