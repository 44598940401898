import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from 'styled-components';

const CrewMember = (props) => {
    const sourceDate = props.member.BirthDate;
    const from = sourceDate.split("-");
    const birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
    const cur = new Date();
    const diff = cur - birthdateTimeStamp;
    const currentAge = Math.floor(diff / 31557600000);

    return (
        <StaffMemberDetails>
            <CrewImage>
                <LazyLoadImage
                    src={props.member.Image}
                    alt={props.member.Firstname}
                    effect="blur"
                    width="200"
                    height="200"
                    delayMethod="throttle"
                    delayTime="400"
                    threshold="100"
                />
            </CrewImage>
            <CrewDetail>
                <StaffMemberHeader>
                    {props.member.FirstName} {props.member.LastName}
                </StaffMemberHeader>
                <StaffMemberDetail>
                    <b>Age:</b> {currentAge}
                </StaffMemberDetail>
                <StaffMemberDetail>
                    <b>Doing Crossfit since:</b> {props.member.Experience}
                </StaffMemberDetail>
                <StaffMemberDetail>
                    <b>Role: </b> {props.member.Role}
                </StaffMemberDetail>
                <a
                    href={"mailto:" + props.member.Email}
                    text={props.member.Email}
                    label="e-mailaddress"
                >{props.member.Email}</a>
            </CrewDetail>

        </StaffMemberDetails>
    )
}
export default CrewMember;


// STYLING
const CrewImage = styled.span`
    box-sizing: border-box;
    float:left;
    padding-right: 24px;
    width: 50%;
    img {
        max-width: 100%;
    }
`
const CrewDetail = styled.span`
    float:left;
    width: 50%;
    h3 {
        margin: 0;
    }
`

const StaffMemberDetails = styled.span`
    float: left;  
    list-style-type: none;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0 ;
    width: 100%;
`
const StaffMemberHeader = styled.h3`
    font-size: 24px;
`
const StaffMemberDetail = styled.span`
    display: block;
    margin: 0 0 12px 0;
`