import React from 'react';
import CrewMember from './../Components/CrewMember';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import FadeIn from 'react-fade-in';
const Crew = (props) => {
    return (
        <>
            <MetaTags>
                <title>{props.pages.Title}</title>
                <meta name="description" content={props.pages.Description} />
            </MetaTags>
            <h1>Crew</h1>
            <CrewList>
                <FadeIn wrapperTag="ul" childTag="li" delay="100">
                    {
                        props.members.map((member, i) => {
                            return (
                                <>
                                    <CrewMember key={i} member={member} />
                                </>

                            );
                        })
                    }
                </FadeIn>
            </CrewList>
        </>
    );
}
export default Crew;

const CrewList = styled.div`
    ul {
        margin: 0;
        list-style-type: none;
        padding: 0 ;
    }
`