import React from 'react';
import MetaTags from 'react-meta-tags';
import ContactForm from './../Components/ContactForm';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';


const Contact = (props) => {
    return (
        <>
            <MetaTags>
                <title>{props.pages.Title}</title>
                <meta name="description" content={props.pages.Description} />
            </MetaTags>

            <FadeIn transitionDuration="800" delay="250">
                <h1>{props.pages.Title}</h1>
                <CompanyInfo>
                    <tr>
                        <td>Company name</td>
                        <td>{props.appInfo.Name}</td>
                    </tr>
                    <tr>
                        <td>Addres</td>
                        <td>{props.appInfo.contactInfo.Street}, {props.appInfo.contactInfo.PostalCode} {props.appInfo.contactInfo.City} </td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td><a href={"tel:" + props.appInfo.contactInfo.PhoneNumber}>{props.appInfo.contactInfo.PhoneNumber}</a></td>
                    </tr>
                    <tr>
                        <td colSpan="2" width="100%">
                            <iframe title="Location" src={props.appInfo.contactInfo.LocationEmbedGMaps} width="100%" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0">

                            </iframe>
                        </td>
                    </tr>
                </CompanyInfo>
                <h2>Form</h2>
                <ContactForm />
            </FadeIn>

        </>
    )
}


export default Contact;

const CompanyInfo = styled.table`
    width: 100%;
    margin-bottom: 24px;
    tr {
        td {            
            &:first-of-type{
                font-weight: bold;
                width: 30%;
            }
            padding: 6px;            
        }
    }
`