import styled from 'styled-components';

const FadeInContainer = styled.div`
    ul {
        margin: 0;
        padding: 0;
        li {
            display: block;
            margin: 0 0 36px 0;
            h2 {
                margin: 0;
                padding: 0
            }
        }
    }
`
export default FadeInContainer;