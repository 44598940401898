import React from 'react';
import MetaTags from 'react-meta-tags';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';

import { colors } from './../Themes/Theme';
const OpeningTimes = (props) => {

    return (
        <>
            <MetaTags>
                <title>{props.pages.Title}</title>
                <meta name="description" content={props.pages.Description} />
            </MetaTags>
            <h1>{props.pages.Title}</h1>
            <StyledOpeningList>
                <FadeIn wrapperTag="ul" childTag="li" delay="100">
                    {
                        props.times.map((time, i) => {
                            return (
                                <span key={i}>
                                    <span><b>{time.Day}</b></span>
                                    <span>{time.Open}</span>
                                    <span>{time.Closed}</span>
                                </span>
                            );
                        })
                    }
                </FadeIn>
            </StyledOpeningList>
        </>
    );

}
export default OpeningTimes;

const StyledOpeningList = styled.div`
    ul {
        float:left;
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
        
        li  {
            border-bottom:solid 1px rgba(255,255,255,0.2);
            display: block;
            float:left;
            margin-bottom: 12px;
            padding-bottom: 12px;
            width: 100%;
            span {
               > span {
                    display: block;
                    float: left;
                    width: calc(100% / 12 * 4);
                }
            }
        }
    }
`