import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from "react-router-dom";
import TagManager from 'react-gtm-module'

import styled, { createGlobalStyle } from 'styled-components'
import Header from './Components/Header';
import Home from './Pages/Home';
import Crew from './Pages/Crew';
import Classes from './Pages/Classes';
import OpeningTimes from './Pages/OpeningTimes';
import Contact from './Pages/Contact';
import Prices from './Pages/Prices';
// import data from "./data";
import Firebase from './Firebase';
import Loader from './Components/LoadSpinner';
import { colors, device } from './Themes/Theme';

const tagManagerArgs = {
  gtmId: 'GTM-WHGZP4Q'
}

TagManager.initialize(tagManagerArgs)
class App extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     data: data
  //   };
  // }
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }
  getUserData = () => {
    let ref = Firebase.database().ref('/');

    ref.on('value', snapshot => {
      const state = snapshot.val();
      this.setState(state);
    });
    console.log('DATA RETRIEVED');
  }
  componentDidMount() {
    this.getUserData();
  }

  render() {
    const statePath = this.state;
    return (

      !this.state.openingTimes ? <LoadScreen /> :
        <>
          <GlobalStyle />
          <Router>
            <Header appInfo={statePath.companySettings} pages={statePath.navList} />
            <Main>
              <Switch>
                <Route exact path="/">
                  <Home
                    pages={statePath.pages.home}
                  />
                </Route>
                <Route path="/opening-times">
                  <OpeningTimes
                    pages={statePath.pages.openingTimes}
                    times={statePath.openingTimes}
                  />
                </Route>
                <Route path="/crew">
                  <Crew
                    pages={statePath.pages.crew}
                    members={statePath.crew}
                  />
                </Route>
                <Route path="/prices">
                  <Prices
                    pages={statePath.pages.prices}
                  />
                </Route>
                <Route path="/classes">
                  <Classes
                    pages={statePath.pages.classes}
                    classes={statePath.classes}
                  />
                </Route>
                <Route path="/contact">
                  <Contact
                    pages={statePath.pages.contact}
                    members={statePath.contact}
                    appInfo={statePath.companySettings}
                  />
                </Route>
                <Route path="*">
                  <div>404</div>
                  <Link to="/">Go to homepage</Link>
                </Route>
              </Switch>
            </Main>
          </Router >
        </>
    );
  }
}
export default App;

const LoadScreen = () => {
  return (
    <>
      <LoadMesage>
        <GlobalStyle />
          <h1>Loading GB2</h1>
        <Loader />
      </LoadMesage>
    </>
  )
}

const LoadMesage = styled.div`
  padding-top: 40vh;
  width: 100%;
  text-align: center;
  h1 {
    text-align: center;
  }
`
const Main = styled.main`
  box-sizing: border-box;
  padding: 114px 24px 24px 24px;
  width: 100%;
`

const GlobalStyle = createGlobalStyle`
  body {
    background: ${colors.darkColor};
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: #FFF;

    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    a {
      color: ${colors.linkColor};
    }
    h1 {
      color: ${colors.linkColor};
      text-shadow: 0 0 12px ${colors.darkColor};
      margin: 0 0 24px 0;
      padding:0;
      text-transform: uppercase;
    }
    p {
      line-height: 1.9rem;
    }
    @media ${device.mobile} { 
      margin:0 0 100px 0;
      bottom: 0;
    }
  }
`