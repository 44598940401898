import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';

import styled from 'styled-components';
import { colors } from '../Themes/Theme';
const Header = (props) => {
    return (
        <PageHeader>
            <Logo>
                <Link to="/">
                    {props.appInfo.Name}
                </Link>
            </Logo>
            <Menu pages={props.pages} />
        </PageHeader>

    )
}
export default Header;

const Logo = styled.span`
    a {
        color: ${colors.linkColor};
        display: block;
        float :left;
        font-size: 24px;
        font-style: italic;
        margin: 32px 0 0 24px;
        text-decoration: none;
        width: 50%;
    }
`

const PageHeader = styled.header`        
    background: ${colors.mainColor};
    height: 90px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index:1;
    ul {
        float: right;
    }
`