import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyAa0AXFkoCg95k78j99-hX7U1WlJDzfQnU",
    authDomain: "gb2app.firebaseapp.com",
    databaseURL: "https://gb2app.firebaseio.com",
    projectId: "gb2app",
    storageBucket: "gb2app.appspot.com",
    messagingSenderId: "907881188024",
    appId: "1:907881188024:web:1f1b8e14f211b37c05024d",
    measurementId: "G-RT3Z4XDFMC"
}
const Firebase = firebase.initializeApp(config);

export default Firebase