import React from 'react';
import MetaTags from 'react-meta-tags';
import FadeIn from 'react-fade-in';

import FadeInContainer from './../Components/FadeInContainer';

const Classes = (props) => {
    return (
        <>
            <MetaTags>
                <title>{props.pages.Title}</title>
                <meta name="description" content={props.pages.Description} />
            </MetaTags>
            <h1>{props.pages.Title}</h1>

            <FadeInContainer>
                <FadeIn transitionDuration="800" delay="250" wrapperTag="ul" childTag="li">
                    {
                        props.classes.map((singleClass, i) => {
                            return (
                                <>
                                    <span key={i}>
                                        <h2><a href={singleClass.Slug}>
                                            {singleClass.ClassName}</a></h2>
                                        <p>Level: {singleClass.Level}</p>
                                        <p>Level: {singleClass.Description}</p>
                                    </span>
                                </>
                            );
                        })
                    }
                </FadeIn>
            </FadeInContainer>
        </>
    );
}
export default Classes;





