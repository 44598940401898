import styled from 'styled-components';
import { colors } from '../Themes/Theme';

const MenuToggle = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    display:block;
    height: 30px;
    position: absolute;
    right: 30px;
    top: 28px;
    width: 40px;
    outline: none;
    z-index: 3;
    &.close {
        span {
            transform-origin: center center;
            &:first-of-type{
                transform: rotate(45deg);
                top: 14px;
                
            }
            &:nth-of-type(2) {
                display: none;
            }
            &:nth-of-type(3) {
                bottom: 12px;
                transform: rotate(-45deg);
            }
        }
    }
    span {
        background: ${colors.fullWhiteColor};
        display: block;
        height: 3px;
        width: 100%;
        position: absolute;
        transform-origin: center center;
        &:first-of-type{
            top: 0;
        }
        &:nth-of-type(2) {
            top: 13px;
        }
        &:nth-of-type(3) {
            bottom: 0;
        }

    }
`
export default MenuToggle;