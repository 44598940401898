import React from 'react';
import styled from 'styled-components';
import {colors} from '../Themes/Theme';

const LoadSpinner = () => {
    return (
        <Loader>
            <div className="loader"></div>
        </Loader>
    )

}

export default LoadSpinner;

const Loader = styled.div`
    .loader,
    .loader:before,
    .loader:after {
        border-radius: 50%;
        width: 1.25em;
        height: 1.25em;
        animation-fill-mode: both;
        animation: load7 1.8s infinite ease-in-out;
    }
    .loader {
        color: ${colors.fullWhiteColor};
        font-size: 10px;
        margin: 0 auto;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
        content: "";
        position: absolute;
        top: 0;
    }
    .loader:before {
        left: -3.5em;
        animation-delay: -0.32s;
    }
    .loader:after {
        left: 3.5em;
    }
    @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
    @keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
`